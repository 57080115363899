export class Address {
  streetNumber: string; //   private String streetNumber;
  streetName: string; //   private String streetName;
  suburb: string; //   private String suburb;
  city: string; //   private String city;
  country: string; // private String country ;
  postcode: number; //   private String postcode ;
  state: string; //   private String state;
  locality: string; //   private String locality;
  addressLine1: string; //   private String addressLine1;
  addressLine2: string; //   private String addressLine2;
  constructor() {}
}
