import { BusinessUser } from 'src/app/model/user';
import { Address } from 'src/app/model/address';
import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';
import { customerAppUrl, dashboardUrl ,businessAppUrl} from './../../app.component';
import { suburbList } from 'src/app/data/cityList.data';
import { serviceType } from 'src/app/data/serviceTypeData';
import { AddressService } from 'src/app/services/address-service.service';
import { Logger } from 'src/app/services/logger.service';
import { TokenStorage } from 'src/app/token.storage';
import { BusinessServices } from 'src/app/home/service/business-services.service';
import { BusinessTypesGroup } from 'src/app/home/home/Home.component';
import { TriggerEventService } from 'src/app/trigger-event.service';

@Component({
   selector: 'app-header',
   templateUrl: './Header.component.html',
   styleUrls: ['./Header.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

   private _router: Subscription;
   deviceInfo: DeviceInfo;
   url: string;
   dashboardUrl: string;
   showHideFlag: boolean = false;
   customerAppUrl: string;
   appBannerAck: boolean = false;
   isFixedClass: boolean = false;
   showAppBanner: boolean = false;
   serviceTypes: string[] = serviceType;
   localityList: any = suburbList;
   suburbsLists: any[] = [];
   cityList: any[] = [];
   address: Address;
   searchForm;
   businessTypeListForSearch: any[] = [];
   businessUser: BusinessUser;
   businessNameList: any[] = [];
   propertyData: any;
  loaderServiceCategoriesByGroup = false;
   businessTypeGroupList: any;
   businessTypesList: any[];
   serviceCategoriesByGroup: any[];
  businesssAppUrl: string;
   constructor(
      private router: Router,
      private triggerEventService: TriggerEventService,
      private deviceService: DeviceDetectorService,
      private cookieService: CookieService,
      private addressService: AddressService,
      private businessService: BusinessServices,
      private token: TokenStorage,
      
    private changeDetectorRefs: ChangeDetectorRef,
      ) {
      this.dashboardUrl = dashboardUrl;
      this.customerAppUrl = customerAppUrl;
      this.businesssAppUrl = businessAppUrl;
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.appBannerAck = this.cookieService.get('bol_ack_app') == 'true';
      this.showAppBanner = this.isMobile() && !this.appBannerAck;
      this.businessUser = new BusinessUser();
      this.address = new Address();
      this.businessUser.address = this.address;
      this.getCityList();
      this.address.city = this.token.getCurrentCity();
   }
   search = (text$: Observable<string>) =>
   text$.pipe(
     debounceTime(200),
     distinctUntilChanged(),
     map((term) =>
       term.length < 3
         ? []
         : this.businessNameList
             .filter((v) =>
               v.toLowerCase().startsWith(term.toLocaleLowerCase())
             )
             .splice(0, 10)
     )
   );
   ngOnInit() {
    this.triggerEventService.events$.forEach((event) =>
      console.log(this.publishPage(event))
    );
      if(this.token.getCurrentCity()== null){
      
         this.address.city = this.cityList[0];
         }
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.appBannerAck = this.cookieService.get('bol_ack_app') == 'true';
      this.showAppBanner = this.isMobile() && !this.appBannerAck;
      // this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      //    this.url = event.url;
      //    if (this.isFixedHeader()) {
      //       this.isFixedClass = true;
      //    }
      //    else
      //    {
      //       this.isFixedClass = false;
      //    }
      // });
   }
   getBusinessTypeListGroup() {
      this.loaderServiceCategoriesByGroup = true;
      this.changeDetectorRefs.detectChanges();
  
      this.businessService.getBusinessTypeListGroup().subscribe(
        (data) => {
          // this.businessTypeGroupList = data.body;
          for (let index = 0; index < data.body.length; index++) {
            const resp = data.body[index];
            const businessTypeGroupListTemp: BusinessTypesGroup = {
              name: resp,
            };
            this.businessTypeGroupList.push(businessTypeGroupListTemp);
          }
          // Logger.log(
          //   'businessTypeGroupList data ',
          //   JSON.stringify(this.businessTypeGroupList)
          // );
          this.token.saveBusinessTypeGroupListData(this.businessTypeGroupList);
          if (this.businessTypeGroupList.length > 0) {
            // this.businessUser.businessTypeGroup = this.businessTypeGroupList[0].name;
          }
          this.loaderServiceCategoriesByGroup = false;
          // this.getBusinessTypeByGroupName(this.businessUser.businessTypeGroup);
  
          this.changeDetectorRefs.detectChanges();
        },
        (error) => {
          this.loaderServiceCategoriesByGroup = false;
        }
      );
    }
    goToHome() {
      this.showHideFlag = false;
      this.changeDetectorRefs.detectChanges();
    }
    publishPage(event: any) {
      if (
        this.token.getProperty() !== undefined &&
        this.token.getProperty() !== null
      ) {
        this.propertyData = this.token.getProperty();
      }
  
      if (event !== null && event! + undefined) {
        this.showHideFlag = true;
      }
  
      console.log('Header ' + event);
      this.changeDetectorRefs.detectChanges();
    }
    getBusinessTypeByGroupName(businessTypeGroupName) {
      this.loaderServiceCategoriesByGroup = true;
      this.businessTypesList = [];
      this.serviceCategoriesByGroup = [];
      this.businessService
        .getBusinessTypeByGroupName(businessTypeGroupName)
        .subscribe(
          (data) => {
            this.businessTypesList = data.body;
            // this.getBusinessTypeCounts();
            this.token.saveBusinessTypeListData(this.businessTypesList);
  
            // Logger.log('businessTypeList data ',JSON.stringify(this.businessTypesList));
            this.serviceCategoriesByGroup = data.body;
  
            if (this.businessTypesList.length > 0) {
              // this.businessUser.businessType = this.businessTypesList[0].name;
            }
            this.loaderServiceCategoriesByGroup = false;
            this.changeDetectorRefs.detectChanges();
          },
          (error) => {
            this.loaderServiceCategoriesByGroup = false;
          }
        );
  
      // this.changeDetectorRefs.detectChanges();
    }
   onBusinessNameSearch(search) {
      if (search.length == 2 ||search.length == 3 || search.length == 1) {
        this.businessService.getBusinessNameBySearch(search).subscribe((data) => {
          this.businessNameList = data.body;
          // Logger.log('businessNameList: ' + JSON.stringify(data.body));
        });
      }
    }

   getCityList() {

      this.addressService
        .getWebsiteCityListByBusinessType("")
        .subscribe((data) => {
          let cityListData = data.body;
          
           
          cityListData.sort((a, b) => a.localeCompare(b));
          // console.log("cityList sdfgh", JSON.stringify(cityListData));
          cityListData.forEach((element) => {
            if (element !=null &&  element != undefined) {
      
              let city = element.split(',');
              this.cityList.push(city[0]);
            }
          
          });
          // let city = data.body.split(',');
  
          this.cityList.sort((a, b) => a.localeCompare(b));
  
          Logger.log("cityList ", JSON.stringify(this.cityList));
        });
    }
   isMobile() {
      return this.deviceService.isMobile();
   }
   onSearchSubmit(){
      this.token.clearProductCart();

      // Logger.log('Home Search', JSON.stringify(this.businessUser));
  
      const navigationExtras: NavigationExtras = {
        queryParams: {
          business: JSON.stringify(this.businessUser),
        },
      };
  
      this.router.navigate(["/listing/list"], navigationExtras);
      
   }
   onCitySelect(selected: any) {
      if (selected !== "") {
        let city = selected.split(",");
        this.getSuburbListByCityName(city[0]);
        this.getBusinessTypesByCity(city[0]);
        this.token.saveCurrentCity(city[0]);
      //   this.businessUser.address.suburb = "";
      } else {
      //   this.businessUser.address.suburb = "";
        this.suburbsLists = [];
        this.businessTypeListForSearch = [];
      //   this.businessUser.businessType = "";
      }
      // Logger.log('localityList selected list: ' + JSON.stringify(selected.index));
    }
    getSuburbListByCityName(CityName) {

      this.addressService.getWebsiteSuburbByCities(CityName).subscribe((data) => {
        this.suburbsLists = data.body;
        this.suburbsLists.sort((a, b) => a[0].localeCompare(b[0]));
  
        Logger.log("suburbsLists ", JSON.stringify(this.suburbsLists));
      });
    }
    getBusinessTypesByCity(selected) {
      // this.loader = true;
      // this.changeDetectorRefs.detectChanges();
  
      this.businessService.getBusinessTypeListByCity(selected).subscribe(
        (data) => {
         //  this.loader = false;
          this.businessTypeListForSearch = data.body;
          this.businessTypeListForSearch.sort((a, b) => a[1].localeCompare(b[1]));
          // this.getBusinessTypeCounts();
          Logger.log(
            "businessTypeList data ",
            JSON.stringify(this.businessTypeListForSearch)
          );
  
         //  this.loader = false;
         //  this.changeDetectorRefs.detectChanges();
        },
        (error) => {
         //  this.loader = false;
        }
      );
    }
   isFixedHeader() {
      if (this.url === '/listing/grid' || this.url === '/listing/list') {
         return true;
      } else {
         return false;
      }
   }

   appBannerAckEvent(clicked: boolean) {
      this.appBannerAck = this.cookieService.get('bol_ack_app') == 'true';
      this.showAppBanner = this.isMobile && !this.appBannerAck;
      this.changeDetectorRefs.detectChanges();
   }

   ngAfterViewInit() {

   }
}
