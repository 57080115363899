import { environment } from './../../../environments/environment';
import { API_URL_PROMOTION } from './../../app.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';


import { BusinessUser } from 'src/app/model/user';
import { API_URL } from 'src/app/app.component';
@Injectable({
  providedIn: 'root'
})
export class BusinessServices {

  constructor(private http: HttpClient) { }

  createUser(applicationUser: BusinessUser) {
     return this.http.post<BusinessUser>(API_URL + '/api/user/signup', applicationUser, { observe: 'response' });
   }
   updateuser(applicationUser: BusinessUser) {
    return this.http.post<BusinessUser>(API_URL + '/api/user/updateUser', applicationUser, { observe: 'response' });
  }
  getBusinessNameBySearch(search) {
    return this.http.get<any[]>(API_URL + '/api/website/findByPropertySearchName/' + search, { observe: 'response' });
  }
  UpdateBusiness(applicationUser: BusinessUser) {
    return this.http.post<BusinessUser>(API_URL + '/api/website/user/update/property', applicationUser, { observe: 'response' });
  }
  getBusinessTypeListGroup() {
    return this.http.get<any[]>(API_URL + '/api/website/businessGroup', { observe: 'response' });
  }
  getBusinessTypeByGroupName(businessGroupName) {
    return this.http.get<any[]>(API_URL + '/api/website/businessType/' + businessGroupName , { observe: 'response' });
  }
  getBusinessTypeList() {
    return this.http.get<any[]>(API_URL + '/api/website/businessTypeList', { observe: 'response' });
  }
  getBusinessTypeListByCity(city) {
    return this.http.get<any[]>(API_URL + '/api/website/getPropertiesByCity/'+ city, { observe: 'response' });
  }
  getBusinessTypeListBySuburb(suburb) {
    return this.http.get<any[]>(API_URL + '/api/website/getPropertiesBySuburb/'+ suburb, { observe: 'response' });
  }
  getPropertiesCountByPropertyType() {
    return this.http.get<any[]>(API_URL + '/api/website/getPropertiesCountByPropertyType', { observe: 'response' });
  }
  getTotalRegisteredProperty() {
    return this.http.get<any[]>(API_URL + '/api/website/getTotalRegisteredProperty', { observe: 'response' });
  }
  getOfferList(){
    return this.http.get<any[]>(API_URL_PROMOTION + '/api/offer/organisationId/'+ environment.parentOrganisationId, { observe: 'response' });
  }
  getOfferListFindBySeoFriendlyName(seoFriendlyName){
    return this.http.get<any[]>(API_URL_PROMOTION + '/api/offer/findBySeofriendlyName/' + seoFriendlyName +'/', { observe: 'response' });
  }
  getOfferById(offerId ) {
    return this.http.get<any[]>(API_URL_PROMOTION + '/api/offer/findById/' + offerId, { observe: 'response' });
  }
  getFeaturedBusinessList() {
    return this.http.get<any[]>(API_URL + '/api/website/findAllFeatured', { observe: 'response' });
  }
  getPropertyDetailsById(propertyId) {
    return this.http.get<any[]>(API_URL + '/api/website/' + propertyId, { observe: 'response' });
  }
}
