<section id="app-banner">
    <div class="app-banner-remove" (click)="dismissAppBanner()"><i class="fa fa-times fa-lg"></i></div>
    <div class="app-banner-icon">
        <a href="{{appUrl()}}" target="_blank"
            class="img-responsive app-banner-action"><img src="assets/images/app-logo.png"></a>
    </div>
    <div class="app-banner-info">
        <a href="{{appUrl()}}" target="_blank" class="app-banner-action">
            <div class="app-banner-title">BookOneLocal on {{deviceInfo.os}}</div>
            <div class="app-banner-subtitle">Easier, simpler, faster</div>
            <div class="app-banner-action-line">Install app</div>
        </a>
    </div>
    <div class="app-banner-action-button">
        <a href="{{appUrl()}}" target="_blank"
            class="btn btn-default app-banner-action">View</a>
    </div>
</section>