export const suburbList = [
  {
    city: 'Auckland central',
    suburbs: [
      'Arch Hill',
      'Auckland CBD',
      'Avondale',
      'Balmoral',
      'Blackpool',
      'Blockhouse Bay',
      'Eden Terrace',
      'Eden Valley',
      'Ellerslie',
      'Epsom',
      'Freemans Bay',
      'Glendowie',
      'Glen Innes',
      'Grafton',
      'Greenlane',
      'Greenwoods Corner',
      'Grey Lynn',
      'Herne Bay',
      'Hillsborough',
      'Kingsland',
      'Kohimarama',
      'Lynfield',
      'Meadowbank',
      'Mission Bay',
      'Morningside',
      'Mount Albert',
      'Mount Eden',
      'Mount Roskill',
      'Mount Wellington',
      'Newmarket',
      'Newton',
      'New Windsor',
      'Onehunga',
      'Oneroa',
      'Onetangi',
      'One Tree Hill',
      'Orakei',
      'Oranga',
      'Ostend',
      'Otahuhu',
      'Owairaka',
      'Palm Beach',
      'Panmure',
      'Parnell',
      'Penrose',
      'Point England',
      'Point Chevalier',
      'Ponsonby',
      'Remuera',
      'Royal Oak',
      'Saint Heliers',
      'Saint Johns',
      'Saint Marys Bay',
      'Sandringham',
      'Stonefields',
      'Tamaki',
      'Te Papapa',
      'Three Kings',
      'Waikowhai',
      'Wai o Taiki Bay',
      'Waterview',
      'Western Springs',
      'Westfield',
      'Westmere',
    ],
  },
  {
    city: 'North Shore',
    suburbs: [
      'Albany',
      'Bayswater',
      'Bayview',
      'Beach Haven',
      'Belmont',
      'Birkdale',
      'Birkenhead',
      'Browns Bay',
      'Campbells Bay',
      'Castor Bay',
      'Chatswood',
      'Cheltenham',
      'Crown Hill',
      'Devonport',
      'Fairview Heights',
      'Forrest Hill',
      'Glenfield',
      'Greenhithe',
      'Hauraki',
      'Highbury',
      'Hillcrest',
      'Long Bay',
      'Mairangi Bay',
      'Marlborough',
      'Milford',
      'Murrays Bay',
      'Narrow Neck',
      'Northcote',
      'Northcote Point',
      'North Harbour',
      'Northcross',
      'Okura',
      'Oteha',
      'Paremoremo',
      'Pinehill',
      'Rosedale',
      'Rothesay Bay',
      'Schnapper Rock',
      'Stanley Bay',
      'Stanley Point',
      'Sunnynook',
      'Takapuna',
      'Torbay',
      'Totara Vale',
      'Unsworth Heights',
      'Waiake',
      'Wairau Valley',
      'Westlake',
      'Windsor Park',
    ],
  },
  {
    city: 'South Auckland and eastern suburbs',
    suburbs: [
      'Airport Oaks',
      'Beachlands',
      'Botany Downs',
      'Brookby',
      'Bucklands Beach',
      'Burswood',
      'Chapel Downs',
      'Clendon Park',
      'Clevedon',
      'Clover Park',
      'Cockle Bay',
      'Dannemora',
      'East Tamaki',
      'East Tamaki Heights',
      'Eastern Beach',
      'Farm Cove',
      'Favona',
      'Flamboro Heights',
      'Flat Bush',
      'Golflands',
      'Goodwood Heights',
      'Greenmeadows',
      'Half Moon Bay',
      'Heron Point',
      'Highland Park',
      'Hillpark',
      'Howick',
      'Huntington Park',
      'Kawakawa Bay',
      'Mahia Park',
      'Mangere',
      'Mangere Bridge',
      'Mangere East',
      'Manukau',
      'Manukau Heights',
      'Manurewa',
      'Maraetai',
      'Meadowlands',
      'Mellons Bay',
      'Middlemore',
      'Murphys Heights',
      'North Park',
      'Orere Point',
      'Ormiston',
      'Otara',
      'Pakuranga',
      'Pakuranga Heights',
      'Papatoetoe',
      'Randwick Park',
      'Settlers Cove',
      'Shelly Park',
      'Silkwood Heights',
      'Somerville',
      'Sunnyhills',
      'The Gardens',
      'Totara Heights',
      'Tuscany Estate',
      'Waimahia Landing',
      'Wattle Cove',
      'Wattle Downs',
      'Weymouth',
      'Whitford',
      'Wiri',
    ],
  },
  {
    city: 'Papakura',
    suburbs: [
      'Alfriston',
      'Ardmore',
      'Conifer Grove',
      'Drury',
      'Longford Park',
      'Manurewa East',
      'Opaheke',
      'Pahurehure',
      'Papakura',
      'Runciman',
      'Red Hill',
      'Rosehill',
      'Takanini',
    ],
  },
  {
    city: 'Pukekohe',
    suburbs: [
      'Awhitu',
      'Ararimu',
      'Bombay',
      'Buckland',
      'Clarks Beach',
      'Hunua',
      'Karaka',
      'Karaka Harbourside',
      'Kingseat',
      'Manukau Heads',
      'Paerata',
      'Paparimu',
      'Patumahoe',
      'Pollok',
      'Pukekohe',
      'Waiau Pa',
      'Waiuku',
    ],
  },
  {
    city: 'West Auckland',
    suburbs: [
      'Anawhata',
      'Cornwallis',
      'Bethells Beach',
      'Glen Eden',
      'Glendene',
      'Green Bay',
      'Henderson',
      'Henderson Valley',
      'Herald Island',
      'Hobsonville',
      'Huia',
      'Karekare',
      'Kelston',
      'Konini',
      'Laingholm',
      'Lincoln',
      'McLaren Park',
      'Massey',
      'New Lynn',
      'Oratia',
      'Parau',
      'Piha',
      'Ranui',
      'Royal Heights',
      'Sunnyvale',
      'Swanson',
      'Te Atatū',
      'Te Atatū Peninsula',
      'Te Atatū South',
      'Titirangi',
      'Waiatarua',
      'Westgate',
      'West Harbour',
      'Western Heights',
      'Whenuapai',
    ],
  },
  {
    city: 'Rodney',
    suburbs: [
      'Algies Bay',
      'Arkles Bay',
      'Army Bay',
      'Big Omaha',
      'Dairy Flat',
      'Dome Forest',
      'Dome Valley',
      'Glorit',
      'Gulf Harbour',
      'Hatfields Beach',
      'Helensville',
      'Huapai',
      'Kaipara Flats',
      'Kaukapakapa',
      'Kumeu',
      'Leigh',
      'Mahurangi East',
      'Mahurangi West',
      'Mangakura',
      'Manly',
      'Makarau',
      'Matakana',
      'Matakatia',
      'Millwater',
      'Muriwai',
      'Omaha',
      'Orewa',
      'Parakai',
      'Pakiri',
      'Point Wells',
      'Port Albert',
      'Puhoi',
      'Red Beach',
      'Redvale',
      'Riverhead',
      'Sandspit',
      'Shelly Beach',
      'Silverdale',
      'Snells Beach',
      'South Head',
      'Stanmore Bay',
      'Tarapora',
      'Tauhoa',
      'Taupaki',
      'Tawharanui Peninsula',
      'Te Arai',
      'Te Hana',
      'Ti Point',
      'Tindalls Beach',
      'Tomarata',
      'Waimauku',
      'Wainui',
      'Waitoki',
      'Waiwera',
      'Warkworth',
      'Whangaparaoa',
      'Whangaripo',
      'Whangateau',
      'Wharehine',
      'Wellsford',
      'Woodhill Forest',
    ],
  },
];
