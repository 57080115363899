export const serviceType = [
    'Accessories and Repair Service',
    'Beauty & Therapy',
    'Builder/Handyman',
    'Cafe & Restaurants',
    'Cleaning Service',
    'Deliveries',
    'Education/Tuition',
    'Electrician/Sparky',
    'Health & Fitness',
    'Heating Solutions',
    'Pet Services',
    'Plumbing Service',
    'Sports & Fun',
    'Takeaways',
    'Others'
];
