import { Routes } from "@angular/router";

import { AdminPanelLayoutComponent } from "./layouts/adminPanel/AdminPanelLayout.component";
import { FrontendPanelLayoutComponent } from "./layouts/frontendPanel/FrontendPanel.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";

export const AppRoutes: Routes = [
  //   {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full',
  // },
  {
    path: "",
    component: FrontendPanelLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
      },

      {
        path: "grocery",
        loadChildren: () =>
          import("./shop/shop.module").then((m) => m.ShopModule),
      },

      {
        path: "listing",
        loadChildren: () =>
          import("./listing/listing.module").then((m) => m.ListingModule),
      },
    ],
  },
];
