import { environment } from 'src/environments/environment';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ANDROID_APP_URL, IOS_APP_URL } from './../../app.component';
import { BusinessServices } from 'src/app/home/service/business-services.service';
import { Logger } from 'src/app/services/logger.service';
import { BusinessUser } from 'src/app/model/user';
import { TriggerEventService } from 'src/app/trigger-event.service';
import { TokenStorage } from 'src/app/token.storage';

@Component({
  selector: 'app-footer',
  templateUrl: './Footer.component.html',
  styleUrls: ['./Footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {
  androidAppUrl: string;
  iosAppUrl: string;
  propertyData: BusinessUser;
  showHideFlag: boolean = false;
  propertyDetails: any = {
    address: '',
    
  };
countryBase: string = environment.country;
parentOrganisationId = environment.parentOrganisationId;
  constructor(
    private triggerEventService: TriggerEventService,
    private changeDetectorRefs: ChangeDetectorRef,
    private token: TokenStorage,
    private businessService: BusinessServices,
    
    // private changeDetectorRefs: ChangeDetectorRef,
  ) {
    this.androidAppUrl = ANDROID_APP_URL;
    this.iosAppUrl = IOS_APP_URL;
    this.triggerEventService.events$.forEach((event) =>
console.log(this.publishPage(event))
);
  }
  ngOnInit(): void {
    // this.publishPage();
// debugger

    this.getOrganisationDetailsById();
  }

  publishPage(event: any) {
    if (
      this.token.getProperty() !== undefined &&
      this.token.getProperty() !== null
    ) {
      this.propertyData = this.token.getProperty();
    }

    if (event !== null && event! + undefined) {
      this.showHideFlag = true;
    }

    console.log('footer ' + event);
    this.changeDetectorRefs.detectChanges();
  }
  getOrganisationDetailsById() {

this.businessService.getPropertyDetailsById(this.parentOrganisationId).subscribe(
  (data) => {
    this.propertyDetails = data.body;
    Logger.log('propertyDetails ' + JSON.stringify(this.propertyDetails) );
  });

  }

}
