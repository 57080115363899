export const environment = {
  production: false,
  appId: 'BookOneLocal',
  apiUrl: 'https://api.bookonelocal.in/api-bookone',
  apiUrlAddress: 'https://api.bookonelocal.in/api-address',
  apiUrlPromotion: 'https://api.bookonelocal.in/promotion-api',
  parentOrganisationId: 1,
  dashboardUrl: 'https://app.bookonelocal.in',
  appUrl: 'https://bookonelocal.in',
  shopUrl: 'BookOneLocal-Food-Grocery',
  customerAppUrl: 'https://bookone-customer-app.web.app',
  businessAppUrl: 'https://bookone-mobile-app.web.app',
  androidAppUrl: 'https://play.google.com/store/apps/details?id=nz.co.bookonelocal.customer',
  iosAppUrl: 'https://apps.apple.com/us/app/bookone-local/id1528910778',
  country: 'in',
  showLog: false,
  tagManagerId: 'GTM-KRDGS9F',
  googleKey: 'AIzaSyBYGJ1SMIW5BIny9Af7eoozmgSGZShhUuE'
};
