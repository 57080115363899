<header class="header-global" *ngIf="showHideFlag === false">

   <app-install *ngIf="showAppBanner" (appBannerAckEvent)="appBannerAckEvent($event)"></app-install>

   <nav id="navbar-main" [ngClass]="{'fixed-top': !showAppBanner}"
      class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom">
      <div class="container">
         <div id="logo">
            <a [routerLink]="['/']">
               <img src="assets/images/logo-white.png" alt=""><span></span>
               <!-- <h6 style="color: white;">Bringing digital closeness in times of social distancing.</h6> -->
            </a>
         </div>
   
         <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_global"
            aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
         </button>
         <div class="navbar-collapse collapse" id="navbar_global">
            <div class="navbar-collapse-header">
               <div class="row">
                  <div class="col-6 collapse-brand">
                     <div id="logo">
                        <a [routerLink]="['/']">
                           <img class="img-fluid" src="assets/images/logo-white.png" width="164" height="25" alt="">
                           <!-- <h2 style="color: white;">Book & Save</h2> -->
                        </a>
                     </div>
                  </div>
                  
                  <div class="col-6 collapse-close">
                     <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbar_global"
                        aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
                        <span></span>
                        <span></span>
                     </button>
                  </div>
               </div>
            </div>
            <div class="navbar-nav navbar-nav-hover align-items-lg-center ml-auto">
           
               <ul class="m-0 p-0">    
                  <!-- <form class="form" (ngSubmit)="onSearchSubmit()">
                  <div class="main-search-input">
                     <div class="main-search-input-item location">
                        <div id="autocomplete-container" >
                           <select class="form-control chosen-select custom-select custom-shadow   text-truncate"
                           id="city" name="city" [(ngModel)]="address.city" #city="ngModel"
                           aria-placeholder="Select City" (ngModelChange)="onCitySelect(address.city)" 
                           required>
                           <option class="text-muted" label="City" value="">City</option>
                      
                           <option class="text-muted" *ngFor="let types of cityList" [value]="types">
                               {{ types }}
                           </option>
                       </select>
      
                        </div>
                        <a href="#"><i class="fa fa-map-marker"></i></a>
                     </div>
                     <div class="main-search-input-item">
                        <input type="text" placeholder="Search By Business Name" id="name" name="name" class="form-control"
                           [(ngModel)]="businessUser.name" 
                           (ngModelChange)="onBusinessNameSearch(businessUser.name)" [ngbTypeahead]="search">
      
                     </div>
      
                     <button type="submit" class="btn main-search-btn btn-lg "
                        [disabled]="loaderServiceCategoriesByGroup  || businessUser.businessType == ''">
      
                        <span class="btn-inner--text"><i class="fa fa-search"></i> </span>
                     </button>
                  </div>
               </form> -->
                  <app-menu></app-menu>
               </ul>
               <div class="header-widget">
                  <nav id="navigation" class="style-1">
                     <ul id="responsive">
                        <li>
                           <a class="current">Sign In / Registration<i class="fa fa-"></i></a>
                           <ul>
                              <li>
                                 <a target="_blank" href="{{customerAppUrl}}"> Customer Login (Mobile Friendly
                                    Version)</a>
                              </li>
                              <li>
                                 <a target="_blank" href="{{businesssAppUrl}}"> Business Login (Mobile Friendly
                                    Version)</a>
                              </li>
                              <li>
                                 <a target="_blank" href="{{dashboardUrl}}"> Business Login</a>
                              </li>
                              <li>
                                 <a [routerLink]="['/registration']" target="_blank"
                                    class="btn btn-info btn-icon btn-radius">List
                                    Your Business
                                    <i class="fa fa-plus"></i>
                                 </a>
                              </li>
                           </ul>
                        </li>
                     </ul>
                  </nav>


               </div>
            </div>
         </div>
      </div>
      <div class="header-widget">
         <!-- <i target="_blank" href="https://www.bookonelocal.com/" class="btn btn-neutral btn-icon btn-radius">Sign In <i class="fa fa-"></i></a> -->
      </div>
   </nav>
</header>

<header class="header-global" *ngIf="showHideFlag === true">
   <nav
     *ngIf="propertyData !== null && propertyData !== undefined"
     id="navbar-main"
     class="navbar navbar-main fixed-top navbar-expand-lg navbar-transparent navbar-light headroom"
   >
     <div class="container-fluid">
      <div style="display:flex;flex-wrap:wrap; gap:10px">
       <div 
         id="logo"
         *ngIf="
           propertyData.logoUrl !== null && propertyData.logoUrl !== undefined
         "
       >
         <a 
           ><img
             src="{{ propertyData.logoUrl }}"
             alt=""
             style="height: 50px"
             
         /></a>
       </div>
       <div>
         <h4 class="header_text">{{propertyData.name}}</h4>
         <h2 class="mobile_view_text">{{propertyData.name}}</h2>
       </div>
      </div>
 
       <!-- <button
         class="navbar-toggler"
         type="button"
         data-toggle="collapse"
         data-target="#navbar_global"
         aria-controls="navbar_global"
         aria-expanded="false"
         aria-label="Toggle navigation"
       >
         <span class="navbar-toggler-icon"></span>
       </button> -->
     </div>
   </nav>
 </header>